<template>
  <MSkin v-if="invalid" skin="dark">
    <MMessageStrip tag="router-link" to="/hub/practice" href="/hub/practice">
      {{ text }}
    </MMessageStrip>
  </MSkin>
</template>

<script setup lang="ts">
import { MSkin, MMessageStrip } from '@boughtbymany/many-patterns'
import { ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'

import { useError } from '@/composables/useError'
import { useAuth } from '@/store/auth'

const auth = useAuth()
const { silentError } = useError()
const { t } = useI18n()

const text = t('messagestrip.text')

const requiredKeys = [
  'name',
  'verified_email',
  'claims_handling_email',
  'remittance_email',
  'address1',
  'town',
  'post_code',
  'county',
  'country',
  'phone',
  'group',
  'phone',
]

const invalid = ref(false)

watchEffect(() => {
  try {
    const company = auth.currentPractice as { [key: string]: any }
    if (company) {
      invalid.value = requiredKeys.some((key) => !company[key])
    }
  } catch (error: any) {
    silentError({
      error,
      message: 'Unable to check if company profile is invalid.',
    })
  }
})
</script>
