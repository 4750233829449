import { usePopover, MLink } from '@boughtbymany/many-patterns'
import { computed, markRaw, ref } from 'vue'

import { heapTrack } from '@/helpers/tracking'
import { isVersionNotificationEnabled } from '@/lib/opsToggle'

export const useVersionNotification = (
  intervalInMilliseconds: number = 300000
) => {
  const popover = usePopover()

  //#region Version check
  const currentVersion = import.meta.env.VITE_COMMIT_REF
  let latestVersion = ''

  const versionNotificationEnabled = computed(() => {
    return isVersionNotificationEnabled()
  })

  const checkAppVersion = async () => {
    //Stop from checking version if notification is visible or FF disabled
    if (isNotificationShowing.value || !versionNotificationEnabled.value) {
      return
    }
    try {
      const response = await fetch(`/version.json?t=${Date.now()}`)
      if (!response.ok) {
        throw new Error('Network response was not ok: ' + response.statusText)
      }

      const { version } = await response.json()

      if (!version) {
        console.info('No version found')
      } else {
        if (version === currentVersion) {
          console.info('App Version:', version)
        } else {
          latestVersion = version
          //Show notification to user
          showNotification()
          heapTrack({
            event: 'Show update version notification',
            location: 'Version notification',
            properties: {
              oldVersion: currentVersion || 'None',
              newVersion: version,
            },
          })
        }
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error)
    }
  }
  //#endregion

  //#region Notification events
  const isNotificationShowing = ref(false)
  const showNotification = () => {
    popover.add({
      type: 'warning',
      bodyComponent: markRaw({
        components: {
          MLink,
        },
        methods: { acceptNotification },
        template: `
        <p>
          A new version of the VetPortal is available.
          <MLink
            icon-right="refresh-cw"
            button-style="secondary"
            @click="acceptNotification"
          >
            Update to the latest version
          </MLink>
        </p>
        `,
      }),
    })
    isNotificationShowing.value = true
  }

  const acceptNotification = () => {
    heapTrack({
      event: 'Accept update version notification',
      location: 'Version notification',
      properties: {
        oldVersion: currentVersion || 'None',
        newVersion: latestVersion,
      },
    })
    window.location.reload()
  }

  const dismissNotification = () => {
    heapTrack({
      event: 'Dismiss update version notification',
      location: 'Version notification',
      properties: {
        oldVersion: currentVersion || 'None',
        newVersion: latestVersion,
      },
    })
    isNotificationShowing.value = false
  }
  //#endregion

  //#region Poll check
  let interval: NodeJS.Timer

  //Set interval for checking app version
  const pollingCheck = () => {
    interval = setInterval(checkAppVersion, intervalInMilliseconds)
  }

  //Set interval by default
  pollingCheck()

  //Listens to page visibility
  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      pollingCheck()
    }
    //Stop interval if page is hidden
    if (document.visibilityState === 'hidden') {
      interval && clearInterval(interval)
    }
  })
  //#endregion

  return {
    acceptNotification,
    dismissNotification,
    showNotification,
    isNotificationShowing,
    currentVersion,
  }
}
