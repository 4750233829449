import dayjs from 'dayjs'

import { PolicyBalance, PolicyCustomer } from '@/domain/application/application'
import {
  PetV2,
  PolicyLimitsCoverage,
  PolicyLimitsSubCoverage,
  PolicyV2,
} from '@/domain/pio/Policy'
import { formatCurrency } from '@/lib/format'
import { getPoliciesV2, getPolicyLimitsV2 } from '@/services/pio/policyService'

export const formatPolicyNumber = ({
  policyNumber,
  prependIBSuiteId,
}: {
  policyNumber: string
  prependIBSuiteId: boolean
}) => {
  const trimmedPolicyNumber = policyNumber.trim()
  let result = trimmedPolicyNumber.replaceAll(/[^A-Za-z0-9]/g, '').toLowerCase()
  if (
    trimmedPolicyNumber.match(/^\d+$/) &&
    trimmedPolicyNumber.length < 10 &&
    prependIBSuiteId
  ) {
    // TODO: We should be ensuring that the trimmedPolicyNumber is 9 characters in length with prepended zeros
    result = 1031268 + trimmedPolicyNumber
  }

  if (trimmedPolicyNumber.match(/^\d{3}-\d{3}-\d{3}$/) && prependIBSuiteId) {
    result = 1031268 + result
  }

  return result
}

export const getBalance = async ({
  policyId,
  petUuid,
  date,
  subcoverages,
}: {
  policyId: string
  petUuid: string
  date?: string
  subcoverages?: PolicyLimitsSubCoverage['name'][]
}) => {
  const resultBalances: PolicyBalance = {
    coverage: '',
    subcoverages: [],
  }

  const point_in_time = dayjs
    .utc(date || undefined)
    .endOf('day')
    .toISOString()

  const limits = await getPolicyLimitsV2(policyId, {
    point_in_time,
  })
  const petCoverages = limits.coverage_limits.find(
    (petLimits) => petLimits.insured_entity === petUuid
  )
  if (!petCoverages) {
    throw Error('No coverages found on policy for pet')
  }

  const vetFeesCoverage = petCoverages.coverages.find(
    (coverage) =>
      coverage.coverage === 'vet-fees' || coverage.coverage === 'vet_fees'
  )
  if (!vetFeesCoverage) {
    throw new Error('No vet fees coverage found for this policy')
  }

  //If subcoverages are provided then calculate balances for each of them
  if (subcoverages) {
    subcoverages.forEach((subcoverageName) => {
      const subcoverage = vetFeesCoverage.subcoverages.find(
        (subcoverage) => subcoverage.name === subcoverageName
      )
      if (subcoverage) {
        const subcoverageBalance = calculateSubcoverageBalance(subcoverage)
        if (subcoverageBalance > 0) {
          resultBalances.subcoverages.push({
            balance: formatCurrency(subcoverageBalance),
            name: subcoverageName,
          })
        }
      }
    })
  }

  const balance = calculateCoverageBalance(vetFeesCoverage)
  resultBalances.coverage = formatCurrency(balance)
  return resultBalances
}

export const calculateCoverageBalance = (coverage: PolicyLimitsCoverage) => {
  return (
    (coverage.policy_limit -
      coverage.amount_spent -
      coverage.amount_in_progress) /
    100
  )
}

export const calculateSubcoverageBalance = (
  coverage: PolicyLimitsSubCoverage
) => {
  return coverage.limit_amount
    ? (coverage.limit_amount -
        coverage.amount_spent -
        coverage.amount_in_progress) /
        100
    : 0
}

export const getVetFeesLimit = (policy: PolicyV2) => {
  if (policy?.product_line === 'cat-dog') {
    return formatCurrency(policy.coverages['vet-fees'].limit)
  }

  return formatCurrency(policy.coverages.vet_fees.limit / 100)
}

export const findPolicies = async ({
  field,
  type,
}: {
  field: string
  type: 'policyNumber' | 'email'
}): Promise<{
  items: PolicyV2[]
  meta: Record<string, any>
}> => {
  if (type === 'email') {
    return await getPoliciesV2({ policy_holder_email: field })
  }

  return await getPoliciesV2ForRef(field)
}

const getPoliciesV2ForRef = async (ref: string) => {
  let policies
  const formattedRef = formatPolicyNumber({
    policyNumber: ref,
    prependIBSuiteId: true,
  })

  policies = await getPoliciesV2({
    ref: formatPolicyNumber({
      policyNumber: ref,
      prependIBSuiteId: false,
    }),
  })

  // Try again with IB Suite ID
  if (
    (!policies.items || policies.items.length === 0) &&
    formattedRef !== ref
  ) {
    policies = await getPoliciesV2({ ref: formattedRef })
  }

  if (!policies.items || policies.items.length === 0) {
    throw new Error('No policies were found for Policy ref')
  }

  return policies
}

export const getPolicyCustomer = (
  policy: PolicyV2,
  pet: PetV2
): PolicyCustomer | undefined => {
  return {
    policy_status: policy.status,
    bbm_insured_entity_uuid: pet.uuid,
    customer_id: policy.customers[0].uuid,
    family_name: policy.customers[0].last_name || '',
    pet_name: pet.name,
    pet_type: pet.species,
    policy_number: policy.ref,
    post_code: pet.postcode,
    start_date: policy.cover.policy_year_start_date,
    first_start_date: policy.cover.first_policy_year_start_date,
    end_date: policy.cover.policy_year_end_date,
    dob: pet.date_of_birth,
    email: policy.customers[0].email,
    count: 1,
    policy_id: pet.policy_id,
  }
}

export const getCover = (
  policy: PolicyV2,
  petUuid: string,
  claim_start_date?: any,
  claim_end_date?: any
) => {
  return policy.pets
    .map((pet) => {
      return {
        excess: formatCurrency(pet?.cover.excesses.excess),
        copay: pet?.cover.excesses.co_payment,
        bbm_insured_entity_uuid: pet.uuid,
        limit: getVetFeesLimit(policy),
        ...(typeof claim_start_date === 'string' && {
          claim_start_date,
        }),
        ...(typeof claim_end_date === 'string' && {
          claim_end_date,
        }),
      }
    })
    .find((pet) => pet.bbm_insured_entity_uuid === petUuid)
}

export const getPolicyDetails = async (
  policy: PolicyV2,
  petUuid: string,
  claim_start_date?: any,
  claim_end_date?: any
) => {
  const pet = policy.pets.find((pet: any) => pet.uuid === petUuid)

  if (!pet) {
    throw new Error('Pet not found on policy')
  }

  const balance = await getBalance({
    policyId: pet.policy_id,
    petUuid,
    date: claim_start_date,
    subcoverages: ['complementary_treatment', 'prescribed_food'],
  })
  const customer = getPolicyCustomer(policy, pet)
  const cover = getCover(policy, petUuid, claim_start_date, claim_end_date)

  return { customer, cover, balance, pet }
}
