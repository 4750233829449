import {
  initialize as ldClientInitialize,
  LDClient,
} from 'launchdarkly-js-client-sdk'

import { HubUser } from '@/domain/vetPortal/HubUser'
import { useFeatureFlags } from '@/store/featureFlags'

export interface VueLaunchDarklyType extends LDClient {
  flags: Record<string, string>
  ready: boolean
}

const useLaunchDarkly = ({
  currentUser,
  currentPractice,
  mainVetPractice,
}: {
  currentUser: HubUser
  currentPractice: string
  mainVetPractice: string
}): VueLaunchDarklyType | null => {
  if (!import.meta.env.VITE_LAUNCH_DARKLY_ID) {
    return null
  }

  const user = {
    anonymous: false,
    key: 'aa0ceb', // default key to prevent MAU being used up on every initialise
    kind: 'user',
  }

  const vet_practice = {
    anonymous: false,
    key: 'aa0ceb', // default key to prevent MAU being used up on every initialise
    kind: 'vet_practice',
  }

  const main_vet_practice = {
    anonymous: false,
    key: 'aa0ceb', // default key to prevent MAU being used up on every initialise
    kind: 'main_vet_practice',
  }

  if (currentUser) {
    user.key = currentUser.id
  }

  if (currentPractice) {
    vet_practice.key = currentPractice
  }

  if (mainVetPractice) {
    main_vet_practice.key = mainVetPractice
  }

  const ldClient = ldClientInitialize(
    import.meta.env.VITE_LAUNCH_DARKLY_ID,
    { kind: 'multi', user, vet_practice, main_vet_practice },
    {
      streaming: import.meta.env.VITE_LAUNCH_DARKLY_STREAMING === 'true',
    }
  )
  const store = useFeatureFlags()

  const client = {
    ...ldClient,
    flags: {},
    ready: false,
  }

  ldClient.on('ready', () => {
    const rawFlags: Record<string, any> = ldClient.allFlags()
    store.setFeatureFlags(rawFlags)

    client.flags = rawFlags
    client.ready = true
  })

  ldClient.on('change', (changes) => {
    const flattened: Record<string, any> = {}
    for (const key in changes) {
      flattened[key] = changes[key].current
    }
    client.flags = { ...client.flags, ...flattened }
    store.setFeatureFlags(client.flags)
  })

  return client
}

export default useLaunchDarkly
