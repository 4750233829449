import { localize } from '@vee-validate/i18n'
import { required, email, min, max } from '@vee-validate/rules'
import { isValid } from 'postcode'
import { defineRule, configure } from 'vee-validate'

// VeeValidate global rules and config
defineRule('required', required)
defineRule('email', email)
defineRule('min', min)
defineRule('max', max)

defineRule('postcode', (value: string) => {
  return isValid(value)
})

configure({
  generateMessage: localize('en', {
    messages: {
      required: 'Please fill out this field',
      email: 'Please enter a valid email address',
      min: 'Please enter at least {length} characters',
      max: 'Please enter no more than {length} characters',
      postcode: 'Please enter a valid UK postcode',
    },
  }),
})
