<template>
  <div class="loading-progress">
    <div class="loading-progress__content">
      <MContainer size="xxs">
        <MProgressBar :length="100" :value="value" />
      </MContainer>
    </div>
  </div>
</template>

<script lang="ts">
import { MProgressBar, MContainer } from '@boughtbymany/many-patterns'
import { defineComponent, ref, watch } from 'vue'
import { Ref } from 'vue'

import { useAuth0 } from '@/composables/useAuth0'

/**
 * Fake loading state whilst we wait for auth so
 * users aren't left starting at a blank screen
 */
export default defineComponent({
  name: 'LoadingProgress',
  components: {
    MProgressBar,
    MContainer,
  },
  setup() {
    return {
      value: ref(5),
      auth0: useAuth0(),
    }
  },
  mounted() {
    this.incrementValue()

    const auth0Loading = this.auth0.loading as Ref<boolean>
    watch(auth0Loading, (loading) => {
      if (loading === false) {
        this.value = 100
      }
    })
  },
  methods: {
    incrementValue() {
      setTimeout(() => {
        if (this.value < 100) {
          const remaining = 100 - this.value
          this.value += remaining / 2
          this.incrementValue()
        }
      }, 1000)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@boughtbymany/many-patterns/src/sass/framework/framework';

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loading-progress {
  align-items: center;
  // Fade in if loading is taking longer than 0.5s
  animation: fade-in 0.5s ease-in-out 0.5s both;
  display: flex;
  // Full height page sections should be 100vh
  // as standard, but if there is a navbar, we
  // need to reduce that by the height of the navbar
  min-height: 100dvh;

  body:has(.m-navbar) & {
    min-height: calc(100dvh - #{$header-height-sm});

    @include breakpoint(sm-up) {
      min-height: calc(100dvh - #{$header-height-lg});
    }
  }

  &__content {
    width: 100%;
  }
}
</style>
