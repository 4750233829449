<template>
  <img
    class="logo"
    :class="{
      'logo--block': block,
    }"
    :src="logo"
    alt="ManyPets Portal"
    :width="width"
    :height="height"
  />
</template>

<script>
export default {
  name: 'Logo',
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      logo: `${import.meta.env.BASE_URL}images/logo.svg`,
    }
  },
  computed: {
    height() {
      // Match the image aspect ratio
      return (this.width * 127.1) / 392
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@boughtbymany/many-patterns/src/sass/framework/framework';
.logo {
  aspect-ratio: 392 / 127.1;
  display: inline-block;

  // Remove 3px invisible border
  &--block {
    display: block;
  }
}
</style>
