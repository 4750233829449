<template>
  <div
    class="search-companies"
    :class="{
      'search-companies--hide-value-whilst-loading': hideValueWhilstLoading,
      'search-companies--loading': loadingCompanies,
    }"
  >
    <MSelectInput
      :placeholder="$t('searchcompanies.placeholder')"
      :options="companies"
      label="name"
      :filterable="false"
      :dropdown-should-open="dropdownShouldOpen"
      v-bind="$attrs"
      :searchable="companies.length > 5"
      @search="handleSearch"
    >
      <template #option="company">
        <strong>{{ company.name }}</strong>
        <br />
        {{ buildAddress(company) }}
      </template>
    </MSelectInput>
  </div>
</template>

<script lang="ts">
import { MSelectInput } from '@boughtbymany/many-patterns'
import { PropType, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useError } from '@/composables/useError'
import { Company } from '@/domain/vetPortal/Company'
import { searchCompanies } from '@/services/companiesService'

interface VueSelect {
  search: string
  modelValue: string
  open: boolean
}

export default defineComponent({
  name: 'SearchCompanies',
  components: {
    MSelectInput,
  },
  props: {
    practiceIds: {
      type: Array as PropType<string[]>,
      default: null,
    },
    hideValueWhilstLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n()
    const { fatalError } = useError()
    return {
      t,
      fatalError,
      loadingCompanies: ref(true),
      companies: ref([] as Company[]),
    }
  },
  mounted() {
    this.handleSearch('')
  },
  methods: {
    buildAddress(company: any) {
      const addressItems = [
        company.address1,
        company.address2,
        company.town,
      ].filter((item) => item)
      return addressItems.join(', ')
    },
    dropdownShouldOpen(VueSelect: VueSelect) {
      // Only open the dropdown after the user has started typing
      return !!(
        (VueSelect.search.length !== 0 || VueSelect.modelValue) &&
        VueSelect.open &&
        (!this.loadingCompanies || this.companies.length)
      )
    },
    async handleSearch(search: string) {
      this.loadingCompanies = true
      if (!search && !this.practiceIds) return
      try {
        const { items: companies } = await searchCompanies({
          search,
          all: true,
          vetPracticeListId: this.practiceIds,
        })

        this.companies = companies
      } catch (error: any) {
        this.fatalError({
          code: 'E13005',
          error,
          message: 'Failed to fetch companies',
        })
      }
      this.loadingCompanies = false
    },
  },
})
</script>

<style lang="scss" scoped>
.search-companies {
  &--hide-value-whilst-loading {
    &.search-companies--loading {
      :deep(.vs__selected) {
        opacity: 0;
      }
    }
  }
}
</style>
