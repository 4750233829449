import { createRouter, createWebHistory } from 'vue-router'

import { heapTrack } from '@/helpers/tracking'
import { useRouter } from '@/store/router'

import Home from '../views/Home.vue'

import Auth0Guard from './guards/Auth0Guard'
import AdminRouters from './routes/admin'
import PortalRouters from './routes/portal'

const routes = [
  ...AdminRouters,
  ...PortalRouters,
  ...[
    {
      path: '',
      name: 'home',
      component: Home,
      beforeEnter: Auth0Guard,
    },
  ],
  {
    path: '/under-maintenance',
    name: 'under-maintenance',
    component: () => import('../views/UnderMaintenance.vue'),
  },
  {
    path: '/uk/documents-upload/:id',
    name: 'documents-upload',
    component: () => import('../views/DocumentsUpload.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../views/Terms/Terms.vue'),
  },
  // Redirect hits on the old login page if people have it bookmarked
  {
    path: '/login',
    redirect: '/',
  },
  // Redirect hits to /uk/ if people got set to manypets and came back
  {
    path: '/uk',
    redirect: '/',
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('../components/error/error.vue'),
    props: true,
  },
  {
    path: '/:catchAll(.*)*',
    name: 'error',
    component: () => import('../components/error/error.vue'),
    props: true,
  },
]

const router = createRouter({
  routes: routes,
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    const routerStore = useRouter()
    const fromHistory = Boolean(savedPosition)

    if (fromHistory && routerStore.routerHistory.length > 0) {
      routerStore.routerHistory.splice(-1, 1)
    } else {
      routerStore.routerHistory.push(from)
    }

    // We have to wait for the animation to finish before scrolling
    setTimeout(() => {
      const left = savedPosition?.left || 0
      const top = savedPosition?.top || 0
      window.scrollTo(left, top)
    }, 300)
  },
})

export default router

router.beforeEach(async (to, _from, next) => {
  const routeName = (to.name || '') as string
  const pageSuccessSession =
    JSON.parse(`${window.sessionStorage.getItem('pageViewSuccess')}`) || []

  if (!pageSuccessSession) {
    window.sessionStorage.setItem('pageViewSuccess', JSON.stringify([]))
  }

  if (
    ['admin-home', 'portal-home'].includes(routeName) &&
    !pageSuccessSession.includes(to.name)
  ) {
    pageSuccessSession.push(to.name)
    window.sessionStorage.setItem(
      'pageViewSuccess',
      JSON.stringify(pageSuccessSession)
    )
    heapTrack({
      location: routeName,
      event: 'Page view',
    })
  }

  if (!to.matched.length) {
    next({
      path: '/error',
    })
  } else {
    next()
  }
})
