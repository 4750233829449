import { RestApi } from '@/lib/api'

export const identifyPerson = async (
  email: string,
  hubUserId: string,
  attributes?: Record<string, string | number | boolean>
) => {
  const payload = {
    type: 'person',
    identifiers: {
      email: email,
    },
    action: 'identify',
    attributes: {
      ...attributes,
      id: hubUserId,
    },
  }

  return sendRequest(payload)
}

interface RequestData {
  type: string
  identifiers: {
    email: string
  }
  attributes: Record<string, string | number | boolean>
}

const sendRequest = async (data: RequestData) => {
  return new RestApi().post('/cio/track/entity', {
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  })
}

const trackCustomerPasswordSet = async (
  email: string,
  user_id: string
): Promise<void> => {
  await identifyPerson(email, user_id, {
    password_set: true,
  })
}

const trackCustomerCompanyRegistered = async (
  email: string,
  user_id: string
) => {
  const registeredDate = new Date()

  await identifyPerson(email, user_id, {
    registration_status: 'REGISTERED',
    registered_date: Math.floor(registeredDate.getTime() / 1000),
  })
}

export default {
  identifyPerson,
  trackCustomerPasswordSet,
  trackCustomerCompanyRegistered,
}
