import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'

export default function init({ app, router }: { app: App; router?: Router }) {
  const sentryDSN = import.meta.env.VITE_SENTRY_DSN
  if (!sentryDSN) {
    return
  }

  const integrations = router
    ? [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ]
    : []

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations,
    logErrors: true,
    tracesSampleRate: 0.2,
    maxBreadcrumbs: 200, // increase from default of 100 to 200
    normalizeDepth: 5, // Increase from default of 3 to 5
    environment: import.meta.env.VITE_ENVIRONMENT,
    release: import.meta.env.VITE_COMMIT_REF,
  })
}
