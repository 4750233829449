const config: { [key: string]: any } = {
  dev: {
    aws_project_region: 'eu-west-1',
    aws_cloud_logic_custom: [
      {
        name: 'manyvetsapi',
        endpoint: 'https://tq7061rst5.execute-api.eu-west-1.amazonaws.com/dev',
        region: 'eu-west-1',
      },
    ],
    aws_appsync_graphqlEndpoint:
      'https://rhaka3w37va6rgbzinntwzmfsy.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AWS_LAMBDA',
    aws_cognito_identity_pool_id:
      'eu-west-1:01f344df-8296-44c6-ad82-68767a5cd912',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_I0BBPWZj4',
    aws_user_pools_web_client_id: '6ou552dnshg37609nc06jdb16v',
    oauth: {},
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    aws_user_files_s3_bucket: 'manyvetsupload171340-dev',
    aws_user_files_s3_bucket_region: 'eu-west-1',
  },
  testing: {
    aws_project_region: 'eu-west-1',
    aws_cloud_logic_custom: [
      {
        name: 'manyvetsapi',
        endpoint: 'https://ekbnqobw22.execute-api.eu-west-1.amazonaws.com/stag',
        region: 'eu-west-1',
      },
    ],
    aws_appsync_graphqlEndpoint:
      'https://2s45acj7ezhrfprrbg7hr7qnci.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AWS_LAMBDA',
    aws_cognito_identity_pool_id:
      'eu-west-1:16b9c599-8e56-4c42-b685-c160241b7086',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_rAmc2qN4V',
    aws_user_pools_web_client_id: '7r6ohjl79uul7irvn306c2jmdo',
    oauth: {},
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    aws_user_files_s3_bucket: 'manyvetsupload151026-stag',
    aws_user_files_s3_bucket_region: 'eu-west-1',
  },
  production: {
    aws_project_region: 'eu-west-1',
    aws_cloud_logic_custom: [
      {
        name: 'manyvetsapi',
        endpoint: 'https://wwizcj6qt2.execute-api.eu-west-1.amazonaws.com/prod',
        region: 'eu-west-1',
      },
    ],
    aws_appsync_graphqlEndpoint:
      'https://xcdwyhpgqjcn3fpeu4iku3vkfe.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AWS_LAMBDA',
    aws_cognito_identity_pool_id:
      'eu-west-1:c62eda6e-7cd5-4858-b701-88f347921251',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_B7nIcMBXO',
    aws_user_pools_web_client_id: '7co8uc0r8l12917ta87mv573di',
    oauth: {},
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ['EMAIL'],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    aws_user_files_s3_bucket: 'manyvetsupload185509-prod',
    aws_user_files_s3_bucket_region: 'eu-west-1',
  },
}

export default config
