export const getMessages = () => {
  const messages = {} as { [key: string]: any }

  const localeFiles = import.meta.glob('../**/locales/*.json', { eager: true })
  const patternsLocaleFiles = import.meta.glob(
    '../../node_modules/@boughtbymany/many-patterns/src/components/**/locales/*.json',
    { eager: true }
  )

  const appendMessages = (files: any) => {
    Object.keys(files).forEach((key: any) => {
      const lang = (
        (key.split('\\').pop() || '').split('/').pop() || ''
      ).replace('.json', '')

      messages[lang] = {
        ...(messages[lang] || {}),
        ...files[key].default,
      }
    })
  }

  appendMessages(localeFiles)
  appendMessages(patternsLocaleFiles)

  return messages
}
