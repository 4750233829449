<template>
  <div />
</template>

<script>
import { watch } from 'vue'
import { useRouter } from 'vue-router'

import { useAuth0 } from '@/composables/useAuth0'

export default {
  name: 'Home',
  setup() {
    const router = useRouter()

    return {
      auth0: useAuth0(),
      router,
    }
  },
  mounted() {
    if (!this.auth0.loading.value) {
      return this.redirect()
    }
    watch(this.auth0.loading, (loading) => {
      if (loading === false) {
        return this.redirect()
      }
    })
  },
  methods: {
    redirect() {
      if (this.auth0.isAuthenticated.value) {
        const role = this.auth0.getUserRole()
        if (['SysAdmin', 'Handler'].includes(role)) {
          return this.router.replace({ name: 'admin-home' })
        }
        return this.router.replace({ name: 'portal-home' })
      }
    },
  },
}
</script>
