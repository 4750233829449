import { VetPractice } from '@/domain/pio/VetPractice'
import { API, SimpleRestApi } from '@/lib/api'

type ItemsResponse<T> = {
  items: T[]
  meta: {
    max_results: number
    page: number
    total: number
  }
}

export const searchVetPractice = async (
  query = ''
): Promise<ItemsResponse<VetPractice>> => {
  const params = {
    q: query,
  }

  return await new API()
    .get('GET_VET_PRACTICES_SEARCH', {
      queryParams: params,
    })
    .then((res) => ({
      items: res?._embedded?.items,
      meta: res?._meta,
    }))
}

export const getVetPractice = async (id: string): Promise<VetPractice> => {
  if (!id) {
    throw new Error('Vet Practice Id not provided')
  }

  const response = await new SimpleRestApi().get(`/vet-practices/${id}`, {
    response: true,
  })

  return response.json()
}

export const getSubBranches = async (
  id: string
): Promise<ItemsResponse<VetPractice>> => {
  if (!id) {
    throw new Error('Main Vet Practice Id not provided')
  }

  return await new API()
    .get('GET_VET_PRACTICES', {
      queryParams: {
        main_branch_id: id,
      },
    })
    .then((res) => ({
      items: res?._embedded?.items,
      meta: res?._meta,
    }))
}
