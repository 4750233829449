import { PolicyV2, PolicyLimitsV2 } from '@/domain/pio/Policy'
import { API } from '@/lib/api'

export const getPoliciesV2 = async (queryStringParameters: {
  owner?: string
  ref?: string
  policy_holder_email?: string
}): Promise<{ items: PolicyV2[]; meta: Record<string, any> }> => {
  const res = await new API().get('GET_POLICIES_V2', {
    queryParams: { ...queryStringParameters, include_legacy: true },
  })
  return {
    items: res?._embedded?.items,
    meta: res?._meta,
  }
}

export const getPolicyV2 = async (
  policyUUID: string,
  queryStringParameters: {
    policy_at_date?: string
  } = {}
): Promise<PolicyV2> => {
  if (!policyUUID) {
    throw new Error('No policy UUID provided')
  }

  return await new API().get('GET_POLICY_V2', {
    pathOptions: { uuid: policyUUID },
    queryParams: { ...queryStringParameters },
  })
}

export const getPolicyLimitsV2 = async (
  policyId: string,
  queryStringParameters: {
    point_in_time?: string
  } = {}
): Promise<PolicyLimitsV2> => {
  if (!policyId) {
    throw new Error('No policy id provided')
  }

  return await new API().get('GET_POLICY_LIMITS', {
    pathOptions: { policyId },
    queryParams: { ...queryStringParameters },
  })
}

export default { getPolicyV2, getPoliciesV2, getPolicyLimitsV2 }
