import dayjs from 'dayjs'

// @TODO: Type should be known before calling this method, fallback should be handled outside of this function.
export const formatCurrency = (value: number | string, fallback = '') => {
  if (!value) return fallback
  const numericValue = typeof value === 'string' ? parseFloat(value) : value

  const formattedValue = new Intl.NumberFormat('en-GB', {
    currency: 'GBP',
    style: 'currency',
  }).format(numericValue)

  return formattedValue.replace('.00', '')
}

export const formatStatus = (status: string) => {
  if (!status) return ''

  if (status === 'DECLINED') {
    return 'In conversation with customer'
  }

  const lowerCaseStatus = status.replace(/_/g, ' ').toLowerCase()

  return lowerCaseStatus.charAt(0).toUpperCase() + lowerCaseStatus.slice(1)
}

export const formatDate = (date?: string) => {
  return date ? dayjs(date).format('DD/MM/YYYY') : '-'
}

export const formatDateTime = (date: string) => {
  return date ? dayjs(date).format('DD/MM/YYYY HH:mm') : '-'
}

export const formatFileName = (fileName: string) => {
  if (!fileName || fileName === null) {
    return ''
  }
  return fileName.split('/').slice(-1)[0]
}

export const titleCase = (txt: string) =>
  txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()

export const formatPhoneNumber = (number: string) => {
  return number.replace(/^(\d)(\d{4})\s*(\d{3})\s*(\d{3})/, '+44 ($1)$2 $3 $4')
}

export const formatSortCode = (sortCode: string) => {
  return sortCode.replace(/^(\d{2})(\d{2})(\d{2})$/, '$1-$2-$3')
}
