import { Company } from '@/domain/vetPortal/Company'
import * as mutations from '@/graphql/mutations'
import * as queries from '@/graphql/queries'
import { apiRequest, apiRequestAll } from '@/lib/api'

import { getHubUser } from './hubUsersService'

export const searchCompanies = async ({
  limit = 1000,
  companyId = '',
  vetPracticeListId = '',
  name = '',
  search = '',
  verifiedEmail = '',
  status = '',
  hasFirstClaimDate = '',
  filterDuplicates = false,
  isDuplicate = false,
  sort = { direction: 'asc', field: 'name' },
  all = false,
  nextToken = '',
}: {
  limit?: number
  companyId?: string | Array<string>
  vetPracticeListId?: string | Array<string>
  name?: string
  search?: string
  verifiedEmail?: string
  status?: string
  hasFirstClaimDate?: string
  filterDuplicates?: boolean
  isDuplicate?: boolean
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  }
  nextToken?: string
  all?: boolean
} = {}) => {
  const variables = {} as { [key: string]: unknown }
  const filters = []

  if (limit) {
    variables.limit = limit
  }

  if (search) {
    const searchKeys = [
      'name',
      'post_code',
      'verified_email',
      'claims_handling_email',
    ]
    filters.push({
      or: searchKeys
        .map((key) => [
          {
            [`${key}`]: {
              matchPhrase: search,
            },
          },
          {
            [`${key}`]: {
              wildcard: `*${search}*`,
            },
          },
        ])
        .flat(),
    })
  }

  if (companyId) {
    if (Array.isArray(companyId)) {
      filters.push({
        or: companyId.map((item) => ({
          company_id: {
            eq: item,
          },
        })),
      })
    } else {
      filters.push({
        company_id: {
          eq: companyId,
        },
      })
    }
  }

  if (vetPracticeListId) {
    if (Array.isArray(vetPracticeListId)) {
      filters.push({
        or: vetPracticeListId.map((item) => ({
          vet_practice_list_id: {
            eq: item,
          },
        })),
      })
    } else {
      filters.push({
        vet_practice_list_id: {
          eq: vetPracticeListId,
        },
      })
    }
  }

  if (status) {
    filters.push({
      status: {
        eq: status,
      },
    })
  }

  if (hasFirstClaimDate) {
    filters.push({
      first_claim_date: {
        exists: hasFirstClaimDate === 'true',
      },
    })
  }

  if (name) {
    filters.push({
      name: {
        eq: name,
      },
    })
  }

  if (verifiedEmail) {
    filters.push({
      verified_email: {
        eq: verifiedEmail,
      },
    })
  }

  if (filterDuplicates) {
    filters.push({
      or: [{ custom_1: { eq: '' } }, { custom_1: { exists: false } }],
    })
  }

  if (isDuplicate) {
    filters.push({
      and: [{ custom_1: { ne: '' } }, { custom_1: { exists: true } }],
    })
  }

  if (filters.length) {
    variables.filter = {
      and: [...filters],
    }
  }

  if (sort) {
    variables.sort = sort
  }

  if (nextToken) {
    variables.nextToken = nextToken
  }

  const requestFunction = all ? apiRequestAll : apiRequest

  const response = await requestFunction({
    query: queries.searchCompanies,
    variables,
  })

  if (all) {
    const items = response as Company[]
    return { items, total: items.length }
  }

  const result = response as {
    data: {
      searchCompanies: { items: Company[]; nextToken?: string; total: number }
    }
  }
  return result.data?.searchCompanies || {}
}

export const createCompany = async ({
  companyId,
  group,
  verifiedEmail,
  name,
  address1,
  address2,
  address3,
  town,
  county,
  country,
  postcode,
  phone,
  website,
  catDogVetAlgoliaId,
  pioVetAlgoliaId,
  claimsHandlingEmail,
  claimsHandlingContactName,
  remittanceEmail,
  vetPracticeListId,
  custom_2,
  type,
}: {
  companyId: string
  group: string
  verifiedEmail: string
  name: string
  address1: string
  address2: string
  address3: string
  town: string
  county: string
  country: string
  postcode: string
  phone: string
  website: string
  catDogVetAlgoliaId: string
  pioVetAlgoliaId: string
  claimsHandlingEmail: string
  claimsHandlingContactName: string
  remittanceEmail: string
  vetPracticeListId: string
  custom_2?: string | boolean
  type: string[]
}) => {
  return apiRequest({
    query: mutations.createCompany,
    variables: {
      input: {
        company_id: companyId,
        group: group,
        verified_email: verifiedEmail,
        name: name,
        address1: address1,
        address2: address2,
        address3: address3,
        town: town,
        county: county,
        country: country,
        post_code: postcode,
        phone: phone,
        website: website,
        cat_dog_vet_algolia_id: catDogVetAlgoliaId,
        pio_vet_algolia_id: pioVetAlgoliaId,
        claims_handling_email: claimsHandlingEmail,
        claims_handling_contact_name: claimsHandlingContactName,
        remittance_email: remittanceEmail,
        vet_practice_list_id: vetPracticeListId,
        type,
        // custom_2 is being set to 'SUB_BRANCH' in the DB
        // to identify sub branch practices, even though it's
        // a free text field.
        // Forcing it here at the service level.
        custom_2: custom_2 ? 'SUB_BRANCH' : '',
      },
    },
  })
}

export const updateCompany = async (practiceDetails: {
  id: string
  name?: string
  address1?: string
  address2?: string
  address3?: string
  town?: string
  county?: string
  country?: string
  post_code?: string
  phone?: string
  website?: string
  company_id?: string
  group?: string
  verified_email?: string
  direct_payment_preferred?: string
  remittance_email?: string
  vat_number?: string
  claims_handling_email?: string
  claims_handling_contact_name?: string
  remittance_contact_name?: string
  practice_manager_name?: string
  custom_1?: string
  custom_2?: string
  custom_3?: string
  cat_dog_vet_algolia_id?: string
  pio_vet_algolia_id?: string
  status?: string
  registered_date?: string
  first_claim_date?: string
  latest_claim_date?: string
  vet_practice_list_id?: string
  type?: string[]
}) => {
  const {
    id,
    name,
    address1,
    address2,
    address3,
    town,
    county,
    country,
    post_code,
    phone,
    website,
    company_id,
    group,
    verified_email,
    direct_payment_preferred,
    remittance_email,
    vat_number,
    claims_handling_email,
    claims_handling_contact_name,
    remittance_contact_name,
    practice_manager_name,
    custom_1,
    custom_2,
    custom_3,
    cat_dog_vet_algolia_id,
    pio_vet_algolia_id,
    status,
    registered_date,
    first_claim_date,
    latest_claim_date,
    vet_practice_list_id,
    type,
  } = practiceDetails
  const response = await apiRequest({
    query: mutations.updateCompany,
    variables: {
      input: {
        id,
        name,
        address1,
        address2,
        address3,
        town,
        county,
        country,
        post_code,
        phone,
        website,
        company_id,
        group,
        verified_email,
        direct_payment_preferred,
        remittance_email,
        vat_number,
        claims_handling_email,
        claims_handling_contact_name,
        remittance_contact_name,
        practice_manager_name,
        custom_1,
        // custom_2 is being set to 'SUB_BRANCH' in the DB
        // to identify sub branch practices, even though it's
        // a free text field.
        // Forcing it here at the service level.
        custom_2: custom_2 ? 'SUB_BRANCH' : '',
        custom_3,
        cat_dog_vet_algolia_id,
        pio_vet_algolia_id,
        status,
        registered_date,
        first_claim_date,
        latest_claim_date,
        vet_practice_list_id,
        type,
      },
    },
  })

  return response.data?.updateCompany || {}
}

export const getCompanyForUser = async ({ userId }: { userId: string }) => {
  const hubUser = await getHubUser(userId)
  if (!hubUser) {
    throw new Error('Hub user not found')
  }

  if (!hubUser.company[0]) {
    throw new Error('Company Id for hub user not found')
  }

  const companies = await getCompanyByCompanyId(hubUser.company[0])
  if (!companies?.items) {
    throw new Error('Company not found')
  }

  const items = companies.items as Company[]
  return items[0] as Company
}

/**
 *
 * @param {string} companyId The Id of the company
 * @returns object
 */
export const getCompanyByCompanyId = async (companyId: string) => {
  const variables = { company_id: companyId }

  const response = await apiRequest({
    query: queries.getCompanyByCompanyId,
    variables,
  })

  return response.data?.getCompanyByCompanyId || { items: [] }
}

/**
 *
 * @param {string} vetPracticeListId The Id of the company
 * @param vetPracticeListId
 * @returns object
 */
export const getCompanyByVetPracticeListId = async (
  vetPracticeListId: string
) => {
  if (!vetPracticeListId) {
    return {}
  }

  const { items } = await searchCompanies({
    vetPracticeListId,
    filterDuplicates: true,
    limit: 1,
  })

  return items[0] || {}
}

export const deleteCompany = async (id: string) => {
  const variables = { input: { id } }

  const response = await apiRequest({
    query: mutations.deleteCompany,
    variables,
  })

  return response.data?.deleteCompany || {}
}
