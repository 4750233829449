import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { reactive, toRefs } from 'vue'

import { useError } from '@/composables/useError'
import { PolicyBalance, PolicyCustomer } from '@/domain/application/application'
import { Cover, PetV2, PolicyV2 } from '@/domain/pio/Policy'
import { findPolicies, getPolicyDetails } from '@/lib/policy'
import { getPolicyV2 } from '@/services/pio/policyService'

type SetPolicyDetailsPayload = {
  policyId?: string
  policyRef?: string
  petUuid: string
  startDate?: string
  endDate?: string
}

export const usePolicyStore = defineStore('policy', () => {
  const { fatalError } = useError()

  const state = reactive({
    selectedPolicy: <PolicyV2 | undefined>undefined,
    selectedPet: <PetV2 | undefined>undefined,
    customer: <PolicyCustomer | undefined>undefined,
    cover: <Cover | undefined>undefined,
    balance: <PolicyBalance>{ coverage: '', subcoverages: [] },
  })

  const selectPolicy = (policy?: PolicyV2) => {
    state.selectedPolicy = policy
  }

  const setPolicyDetails = async ({
    policyId,
    policyRef,
    petUuid,
    startDate,
    endDate,
  }: SetPolicyDetailsPayload) => {
    try {
      if (policyRef) {
        //Draft claims does not contain a policy_id so we need to use the policy_ref to get find the policy first
        const { items: policies } = await findPolicies({
          field: policyRef,
          type: 'policyNumber',
        })
        policyId = policies[0].pets[0].policy_id
      }

      //No need to call if policy is provided already
      if (!state.selectedPolicy) {
        if (!policyId) {
          throw new Error('Policy id not provided')
        }
        if (!startDate) {
          throw new Error('Start date not provided')
        }
        state.selectedPolicy = await getPolicyV2(policyId, {
          policy_at_date: dayjs(startDate).format('YYYY-MM-DD'),
        })
      }

      const { balance, cover, customer, pet } = await getPolicyDetails(
        state.selectedPolicy,
        petUuid,
        startDate,
        endDate
      )
      state.selectedPet = pet
      state.balance = balance
      state.cover = cover
      state.customer = customer
    } catch (error: any) {
      fatalError({
        message: `Unable to load policy details`,
        code: 'E2021',
        error,
        extra: {
          data: {
            policy_id: policyId,
            policy_ref: policyRef,
            pet_uuid: petUuid,
          },
        },
      })
    }
  }

  const reset = () => {
    state.balance = { coverage: '', subcoverages: [] }
    state.cover = undefined
    state.customer = undefined
    state.selectedPet = undefined
    state.selectedPolicy = undefined
  }

  return {
    ...toRefs(state),
    selectPolicy,
    setPolicyDetails,
    reset,
  }
})
